const Navigation = (function() {
    let settings = {
        displaying: false,
        element: document.querySelector('[data-js-navigation]'),
        hideClass: 'navigation--hidden',
        nav: document.querySelector('[data-js-nav]'),
        navHideClass: 'navigation__nav--hidden',
    };

    const toggleDisplay = function() {
        if (settings.displaying) {
            hideNavigation();
        } else {
            showNavigation();
        }
    };

    const showNavigation = function() {
        settings.element.classList.remove(settings.hideClass);
        settings.nav.classList.remove(settings.navHideClass);
        document.body.style.maxHeight = '100vh';
        settings.displaying = true;
    }

    const hideNavigation = function() {
        settings.element.classList.add(settings.hideClass);
        settings.nav.classList.add(settings.navHideClass);
        document.body.style.maxHeight = 'unset';
        settings.displaying = false;
    }

    const initEventListeners = function() {
        const menuButton = document.querySelector('[data-js-menu]');
        if (menuButton) {
            menuButton.addEventListener('click', toggleDisplay);
        }

        const navigationWrapper = settings.element;
        if (navigationWrapper) {
            navigationWrapper.addEventListener('click', toggleDisplay);
        }

        document.onkeydown = function(evt) {
            let isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }
            if (isEscape && settings.displaying) {
                toggleDisplay();
            }
        };
    };

    const init = function() {
      initEventListeners();
    };

    return {
      init: init
    };

  })();

  export default Navigation;