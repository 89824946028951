import Navigation from "./scripts/navigation";

// Handle navigation menu stuff
Navigation.init();

// When the user scrolls the page, execute myFunction
window.onscroll = function() {
    stickyHeader()
};

const header = document.querySelector('[data-js-header]');
const logo = header.querySelector('[data-js-header-logo]');
const main = document.querySelector('[data-js-main]');
const sticky = header.offsetTop;

function stickyHeader() {
  if (window.scrollY > sticky) {
    header.classList.add("sticky");
    main.classList.add("sticky__content");
    logo.classList.add("header__logo--sticky");
  } else {
    header.classList.remove("sticky");
    main.classList.remove("sticky__content");
    logo.classList.remove("header__logo--sticky");
  }
}